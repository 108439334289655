import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Button } from './components';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h3>
          Mumbo Jumbo
        </h3>
        <Button
          type='primary'
          text='Coming Soon'
          onClick={() => console.log('Coming Soon')}
        />
      </header>
    </div>
  );
}

export default App;
