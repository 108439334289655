
import React from "react";
import './icon.css';


export enum IconType {
    none
}

export enum IconSize {
    xxxxsmall = "xxxxsmall",
    xxxsmall = "xxxsmall",
    xxsmall = "xxsmall",
    xsmall = "xsmall",
    small = "small",
    medium = "medium",
    large = "large",
    xlarge = "xlarge",
    xxlarge = "xxlarge",
    xxxlarge = "xxxlarge",
}

// Handles SVG images
const Icon = (props: { icon: IconType, size?: IconSize, isPrimary?: boolean, color?: string, disabled?: boolean, capsule?: boolean, onClick?: () => void }) => {

    const size = props.size ?? '';
    const isPrimary = props.isPrimary ? 'icon-primary' : '';
    const isDisabled = props.disabled ? 'disabled' : '';
    const shouldHover = props.onClick ? 'hover' : '';
    const isCapsule = props.capsule ? 'capsule' : '';
    const color = props.color ? `icon-color-${props.color}` : '';

    const icon = () => {
        switch (props.icon) {
            default:
                return <div className={`${size}`}></div>
        }
    }

    return (
        <div className={`icon-container ${shouldHover} ${isCapsule}`} onClick={() => props.onClick && props.onClick()}>
            {icon()}
        </div>
    );
}

export default Icon;