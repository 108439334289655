import React from "react";
import './button.css';
import { spinnerBlack, spinnerWhite } from "../static";
import Icon, { IconType, IconSize } from "./icon";

const Button = (props: {
    className?: string,
    id?: string,
    text?: string,
    fill?: boolean,
    icon?: IconType,
    iconSize?: IconSize | undefined,
    type: string, 
    onClick?: () => void,
    onSubmit?: (e: any) => void,
    disabled?: boolean,
    loading?: boolean,
    submit?: boolean,
    selected?: boolean
}
) => {
    const needsMin = props.text !== undefined ? "needs-min" : "";
    const isPrimary = props.type.includes("primary");
    const isInline = props.type.includes("inline");
    const currentTheme = localStorage.getItem("theme") ?? "light";
    const spinner = isPrimary ? spinnerWhite : currentTheme === "light" ? spinnerBlack : spinnerWhite;

    const content = () => {
        if (props.loading) {
            return (
                <div className="button-group">
                    <img src={spinner} alt="spinner" className={`spinner ${isInline ? "spinner-inline" : ""}`} />
                </div>
            )
        }
        return (
            <div className="button-group">
                {props.icon && <> <Icon icon={props.icon} size={props.iconSize ?? IconSize.xxxsmall} isPrimary={isPrimary || props.selected} />
                    {props.text && <>&nbsp;&nbsp;&nbsp;&nbsp;</>}
                </>}
                {props.text && props.text}
            </div>
        );
    }

    const handleClick = () => {
        if (!props.loading && props.onClick) {
            props.onClick();
        }
    }

    return (
        <button
            type={props.submit ? "submit" : "button"}
            className={`button ${props.type} ${props.selected && "selected"} ${needsMin} ${props.fill ? "fill" : ""}`}
            onClick={!props.submit ? handleClick : undefined}
            disabled={props.disabled}
        >
            {content()}
        </button>
    );
}


export default Button;